<template>
  <b-card body-class="p-0 mb-2" header-tag="header" :header-class="['card-row', {rounded: !this.expand}]">
    <template #header>
      <b-container fluid class="px-1 py-2">
        <b-row class="clickable" @click.self="details">
          <b-col cols="4" @click.self="details">
            <ComputerInformation :pc="pc" @click.self="details" @expand="details"/>
          </b-col>
          <b-col cols="4" @click.self="details">
            <CustomerInformation :pc="pc" @click.self="details" @expand="details"/>
          </b-col>
          <b-col cols="4" @click="details">
            <LicenseInformation :pc="pc"/>
          </b-col>
        </b-row>
      </b-container>
    </template>
    <TransitionExpand>
      <LicenseDetails v-if="expand" :pc="pc" :fg="fg" @add-comment="addComment" @update-comment="updateComment"
                      @refresh="refresh"/>
    </TransitionExpand>
  </b-card>
</template>

<script>
import LicenseDetails from "@/components/lists/licensesList/LicenseDetails";
import TransitionExpand from "@/components/utility/TransitionExpand";
import ComputerInformation from "@/components/lists/licensesList/utility/ComputerInformation";
import CustomerInformation from "@/components/lists/licensesList/utility/CustomerInformation";
import LicenseInformation from "@/components/lists/licensesList/utility/LicenseInformation";

export default {
  name: "LicenseRow",
  props: ['pc', 'extended', 'fg'],
  components: {
    LicenseDetails,
    TransitionExpand,
    LicenseInformation,
    CustomerInformation,
    ComputerInformation
  },
  data() {
    return {
      expand: this.extended
    }
  },
  methods: {
    details() {
      this.expand = !this.expand
    },
    refresh() {
      this.$emit('refresh')
    },
    addComment(data) {
     this.$emit('add-comment', data)
    },
    updateComment(data) {
      this.$emit('update-comment', data)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
