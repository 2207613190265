<template>
  <div>
    <div>Kategorie: {{ comment.license_comment_category.displayname }}</div>
    <div>Zugänglichkeit: {{ comment.license_comment_priority.displayname }}</div>
    <div class="mt-2">Nachricht:</div>
    <div class="ml-3 pre-formatted">{{ comment.message }}</div>
    <div class="text-secondary">
      von {{ comment.user.username }} am
      {{ comment.created_at | date }} um
      {{ comment.created_at | time }}
    </div>
    <div v-if="comment.expire" class="text-secondary">
      Kommentar läuft aus am {{ comment.expire | date }}
    </div>
    <div v-else class="text-secondary">
      Kommentar läuft nicht aus
    </div>
    <div v-if="comment.license_comment_notice" class="list-sub-row-entry  p-2">
      <div>Anmerkung der FG_Software:</div>
      <div class="ml-3 pre-formatted">{{ comment.license_comment_notice.message }}</div>
      <div class="text-secondary">
        von {{ comment.license_comment_notice.user.username }} am
        {{ comment.license_comment_notice.created_at | date }} um
        {{ comment.license_comment_notice.created_at | time }}
      </div>
    </div>
    <div v-if="comment.verified" class="text-success mt-3">
      Der Kommentar wurde von der FG_Software verifiziert.
    </div>
    <div v-if="comment.license_comment_notice" class="text-danger mt-3">
      Der Kommentar wurde von der FG_Software als nicht zutreffend bzw. zu ungenau angemerkt.
    </div>
  </div>
</template>

<script>
export default {
  name: "CommentInformation",
  props: ['comment']
}
</script>

<style lang="scss" scoped>
</style>
