<template>
  <b-row class="p-0">
    <b-col cols="6" class="pl-0">
      <div v-if="pc.customer" @click.self="expand">
        <span v-b-tooltip.hover.top :title="pc.customer.zuname + ', ' + pc.customer.vorname">
          <Clipboard :value="pc.customer.kdnr"/>
        </span>
        <span v-if="kdnr">
          <a target="_blank" :href="'https://webams.alfanetz.de/teilnehmer/Teilnehmer/' + kdnr">
            <b-icon-link45deg :id="kdnr"/>
            <b-tooltip :target="kdnr">Link zum AMS</b-tooltip>
          </a>
        </span>
        <div :class="{'text-danger': customerWarning}" @click.self="expand">
          Letzte Anmeldung vor {{ pc.customer.time_login | dateDiffDays }}
        </div>
      </div>
      <div v-else-if="pc.customer_rpc" @click.self="expand">
        <span v-b-tooltip.hover.top :title="pc.customer_rpc.zuname + ', ' + pc.customer_rpc.vorname">
          <Clipboard :value="pc.customer_rpc.kdnr"/>
        </span>
        <span v-if="kdnr">
          <a target="_blank" :href="'https://webams.alfanetz.de/teilnehmer/Teilnehmer/' + kdnr">
            <b-icon-link45deg :id="kdnr"/>
            <b-tooltip :target="kdnr">Link zum AMS</b-tooltip>
          </a>
        </span>
        <div :class="{'text-danger': customerWarning}">
          Letzte Anmeldung vor {{ pc.customer_rpc.time_login | dateDiffDays }}
        </div>
      </div>
      <div v-else-if="pc.customer_assigned" @click.self="expand">
        <span v-b-tooltip.hover.top :title="pc.customer_assigned.zuname + ', ' + pc.customer_assigned.vorname">
          <Clipboard :value="pc.customer_assigned.kdnr"/>
        </span>
        <span v-if="kdnr">
          <a target="_blank" :href="'https://webams.alfanetz.de/teilnehmer/Teilnehmer/' + kdnr">
            <b-icon-link45deg :id="kdnr"/>
            <b-tooltip :target="kdnr">Link zum AMS</b-tooltip>
          </a>
        </span>
        <div :class="{'text-danger': customerWarning}">
          Letzte Anmeldung vor {{ pc.customer_assigned.time_login | dateDiffDays }}
        </div>
      </div>
      <div v-else @click="expand">-</div>
    </b-col>
    <b-col cols="6" class="pl-0" @click="expand">
      <div v-if="pc.customer">{{ pc.customer.kurs }}</div>
      <div v-else-if="pc.customer_rpc">{{ pc.customer_rpc.kurs }}</div>
      <div v-else-if="pc.customer_assigned">{{ pc.customer_assigned.kurs }}</div>
      <div v-else>-</div>
    </b-col>
  </b-row>
</template>

<script>
import dayjs from "dayjs";
import Clipboard from "@/components/utility/Clipboard.vue";

export default {
  name: "CustomerInformation",
  components: {
    Clipboard
  },
  props: ['pc'],
  methods: {
    expand() {
      this.$emit('expand')
    }
  },
  computed: {
    kdnr() {
      let reg = /(\d*)\D{2}/
      if (this.pc.customer && this.pc.customer.kdnr) {
        if (this.pc.customer.kdnr[0] === 'D' || this.pc.customer.kdnr[0] === 'T') {
          return null
        }
        return this.pc.customer.kdnr.match(reg)[1]
      }
      else if (this.pc.customer_rpc && this.pc.customer_rpc.kdnr) {
        if (this.pc.customer_rpc.kdnr[0] === 'D' || this.pc.customer_rpc.kdnr[0] === 'T') {
          return null
        }
        return this.pc.customer_rpc.kdnr.match(reg)[1]
      }
      else if (this.pc.customer_assigned && this.pc.customer_assigned.kdnr) {
        if (this.pc.customer_assigned.kdnr[0] === 'D' || this.pc.customer_assigned.kdnr[0] === 'T') {
          return null
        }
        return this.pc.customer_assigned.kdnr.match(reg)[1]
      }
      return null
    },
    customerWarning() {
      let warning = false
      if (this.pc.customer && dayjs().diff(dayjs(this.pc.customer.time_login), 'days') >= 10) {
        warning = true
      } else if (this.pc.customer_rpc && dayjs().diff(dayjs(this.pc.customer_rpc.time_login), 'days') >= 10) {
        warning = true
      } else if (this.pc.customer_assigned && dayjs().diff(dayjs(this.pc.customer_assigned.time_login), 'days') >= 10) {
        warning = true
      }
      return warning
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
