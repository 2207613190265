<template>
  <div>
    <div v-if="comment" class="mb-3">
      <b-row class="mb-1">
        <b-col cols="auto" class="mr-auto">{{ software.displayname }}</b-col>
        <b-col cols="auto" v-if="fg">
          <b-icon-eye-fill v-if="commentRead" :variant="variantSuccess" class="clickable" scale="1.4"
                           @click="changeCommentStatus"/>
          <b-icon-eye-slash-fill v-else :variant="variantDanger" class="clickable" scale="1.4" @click="changeCommentStatus"/>
        </b-col>
        <b-col cols="auto" v-else>
          <b-badge size="sm" variant="primary" class="ml-3 clickable" @click="updateComment">
            Kommentar ändern
          </b-badge>
        </b-col>
      </b-row>
      <b-list-group v-if="comment" :id="fg ? 'comment-' + comment.id: ''"
                    :class="[{'clickable' : fg}, {'list-border-success' : comment.verified},
                             {'list-border-danger' : comment.license_comment_notice}, 'list-sub-row']">
        <b-list-group-item class="list-sub-row d-flex ">
          <CommentInformation :comment="comment"/>
        </b-list-group-item>
      </b-list-group>
      <CommentPopover :fg="fg" :comment="comment" @refresh="refresh"/>
    </div>
  </div>
</template>

<script>
import {putRequest} from "@/modules/requests";
import CommentPopover from "@/components/lists/licensesList/comment/utility/CommentPopover";
import CommentInformation from "@/components/lists/licensesList/comment/utility/CommentInformation";
import {mapGetters} from "vuex";

export default {
  name: "Comment",
  props: ['comment', 'software', 'fg', 'license'],
  components: {
    CommentPopover,
    CommentInformation
  },
  data() {
    return {
      commentRead: this.comment.read
    }
  },
  methods: {
    updateComment() {
      this.$emit('update-comment', {comment: this.comment, license: this.license})
    },
    refresh() {
      this.$emit('refresh')
    },
    async changeCommentStatus() {
      await putRequest('/licenses/fg/comments/read/' + this.comment.id, null, this, 'Kommentarstatus geändert.', 'Kommentarstatus konnte nicht geändert werden.')
          .then(() => {
            this.commentRead = !this.commentRead
          })
    }
  },
  computed: {
    ...mapGetters(['userColorAccessibility']),
    variantSuccess() {
      if(this.userColorAccessibility === 'red-green') {
        return 'success-deuteranopia'
      }
      return 'success'
    },
    variantDanger() {
      if(this.userColorAccessibility === 'red-green') {
        return 'danger-deuteranopia'
      }
      return 'danger'
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
