<template>
  <div v-if="pc.disks.length > 1">
    <div v-for="disk in pc.disks" :key="disk.id">
      <div>Festplatte: {{ disk.sn }}</div>
      <span v-for="license in disk.licenses" :key="license.id">
        <span v-if="!license.allowed">
        <b-badge v-if="license.comment === 'commented'" class="mr-2" :variant="variantWarning">
          {{ license.license.software.displayname }}
        </b-badge>
        <b-badge v-else-if="license.comment === 'verified'" class="mr-2 border-success" :variant="variantWarning">
          {{ license.license.software.displayname }}
        </b-badge>
          <b-badge v-else-if="license.comment === 'noticed'" class="mr-2 border-danger" :variant="variantWarning">
          {{ license.license.software.displayname }}
        </b-badge>
        <b-badge v-else class="mr-2" :variant="variantDanger">
          {{ license.license.software.displayname }}
        </b-badge>
      </span>
        <b-badge v-else class="mr-2" :variant="variantSuccess">
        {{ license.license.software.displayname }}
      </b-badge>
      </span>
    </div>
  </div>
  <div v-else>
    <span v-for="license in pc.disks[0].licenses" :key="license.id">
      <span v-if="!license.allowed">
        <b-badge v-if="license.comment === 'commented'" class="mr-2" :variant="variantWarning">
          {{ license.license.software.displayname }}
        </b-badge>
        <b-badge v-else-if="license.comment === 'verified'" class="mr-2 border-success" :variant="variantWarning">
          {{ license.license.software.displayname }}
        </b-badge>
        <b-badge v-else-if="license.comment === 'noticed'" class="mr-2 border-danger" :variant="variantWarning">
          {{ license.license.software.displayname }}
        </b-badge>
        <b-badge v-else class="mr-2" :variant="variantDanger">
          {{ license.license.software.displayname }}
        </b-badge>
      </span>
      <b-badge v-else class="mr-2" :variant="variantSuccess">
        {{ license.license.software.displayname }}
      </b-badge>
    </span>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "LicenseInformation",
  props: ['pc'],
  computed: {
    ...mapGetters(['userColorAccessibility']),
    variantSuccess() {
      if(this.userColorAccessibility === 'red-green') { return 'success-deuteranopia' }
      return 'success'
    },
    variantDanger() {
      if(this.userColorAccessibility === 'red-green') { return 'danger-deuteranopia' }
      return 'danger'
    },
    variantWarning() {
      if(this.userColorAccessibility === 'red-green') { return 'warning-deuteranopia' }
      return 'warning'
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../../assets/styles/Licenses';
</style>
