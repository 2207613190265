<template>
  <b-row class="p-0">
    <b-col cols="6" class="p-0">
      <div @click.self="expand">
        <Clipboard :value="pc.hostname" title="Hostname kopieren"/>
      </div>
      <div @click.self="expand">
        <Clipboard :value="pc.ipv4_address" title="IPv4-Adresse kopieren"/>
      </div>
    </b-col>
    <b-col cols="6" class="p-0" @click="expand">
      <div>Zuletzt gemeldet am:
        <div :class="{'text-danger': pcWarning}">{{ pc.time_last_seen | datetime }}</div>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import dayjs from "dayjs";
import Clipboard from "@/components/utility/Clipboard.vue";

export default {
  name: "ComputerInformation",
  components: {
    Clipboard
  },
  props: ['pc'],
  methods: {
    expand() {
      this.$emit('expand')
    }
  },
  computed: {
    pcWarning() {
      return dayjs().diff(dayjs(this.pc.time_last_seen), 'days') >= 30
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
