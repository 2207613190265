<template>
  <div>
    <b-row>
      <b-col cols="2"/>
      <b-col cols="8" style="font-size: 14px">
        <SubHeader>
          <LicenseFilter class="mt-2" :fg="fg" :filteredCount="filteredCount" @search-changed="setSearch"
                         @filter-options="setFilterOptions"/>
        </SubHeader>
        <div v-if="groupsFiltered.length > 0">
          <div v-for="group in groupsFiltered" :key="group.id">
            <Group :group="group" class="mt-3">
              <template #group-content>
                <div v-for="computer in computers(group)" :key="computer.id">
                  <LicenseRow :pc="computer" :extended="!!(hostname)" :fg="fg" @add-comment="addComment"
                              @update-comment="updateComment" @refresh="refresh"/>
                </div>
              </template>
            </Group>
          </div>
        </div>
        <b-row v-else cols="1">
          <b-col>
            <h5 class="text-secondary text-center mt-5">Keine Daten vorhanden</h5>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="2"/>
    </b-row>
    <ScrollToTop/>
    <LicenseCommentModal :licenseId="licenseInstallId" :comment="comment" :method="modalMethod" modalId="0"
                         @refresh="refresh"/>
  </div>
</template>

<script>
import _ from "lodash";
import {postRequest} from "@/modules/requests";
import {mapActions} from "vuex";
import Group from "@/components/lists/Group";
import SubHeader from "@/components/structure/SubHeader";
import ScrollToTop from "@/components/utility/ScrollToTop";
import LicenseFilter from "@/components/lists/licensesList/LicenseFilter.vue";
import LicenseRow from "@/components/lists/licensesList/LicenseRow";
import LicenseCommentModal from "@/components/lists/licensesList/LicenseCommentModal.vue";

export default {
  name: "LicenseList",
  components: {
    Group,
    SubHeader,
    ScrollToTop,
    LicenseRow,
    LicenseFilter,
    LicenseCommentModal
  },
  data() {
    return {
      licenses: [],
      search: '',
      filteredCount: 0,
      filterOptions: null,
      fg: this.$route.meta.fg,
      licenseInstallId: null,
      modalMethod: 'add',
      comment: null
    }
  },
  methods: {
    ...mapActions(['selectSite']),
    ...mapActions(['setLoadingState']),
    setSearch(data) {
      this.search = data
    },
    setFilterOptions(data) {
      this.filterOptions = data
      this.refresh()
    },
    addComment(id) {
      this.licenseInstallId = id
      this.modalMethod = 'add'
      this.$bvModal.show('licenseCommentModal-0')
    },
    updateComment(data) {
      this.comment = data.comment
      this.licenseInstallId = data.license.id
      this.modalMethod = 'update'
      this.$bvModal.show('licenseCommentModal-0')
    },
    computers(group) {
      return _.filter(group.computers, (computer) => {
        return computer.hostname.toLowerCase().includes(this.search.toLowerCase())
      })
    },
    async refresh() {
      this.setLoadingState(true)
      await this.getLicenses()
      this.setLoadingState(false)
    },
    async getLicenses() {
      this.licenses = []
      await postRequest('licenses/all', this.filterOptions, this, null)
          .then((response) => {
            this.filteredCount = response.data.license_count
            this.licenses = response.data.groups
          })
    }
  },
  computed: {
    hostname() {
      if (this.$route.query.hostname) {
        return this.$route.query.hostname
      }
      return null
    },
    groupsFiltered() {
      return _.filter(this.licenses, (group) => {
        return _.filter(group.computers, (computer) => {
          return computer.hostname.toLowerCase().includes(this.search.toLowerCase())
        }).length > 0
      })
    }
  },
  beforeMount() {
    if(this.$route.query && this.$route.query.site) {
      this.selectSite(this.$route.query.site)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
