<template>
  <b-card-body class="license-expand pt-1">
    <b-container fluid class="p-0">
      <b-row class="p-0 mt-2">
        <b-col cols="12">
          <div v-for="disk in pc.disks" :key="disk.id">
            <div v-for="license in disk.licenses" :key="license.id" class="mb-1">
              <Comment v-if="license.license.comment" :comment="license.license.comment" :fg="fg" :license="license"
                       :software="license.license.software" @update-comment="updateComment" @refresh="refresh"/>
              <div v-if="!license.license.comment && license.license.warning">
                <b-row class="mx-0">
                  <b-col cols="6" class="p-0">
                    <span>{{ license.license.software.displayname }}</span>
                  </b-col>
                  <b-col cols="auto" class="p-0 ml-auto">
                    <span class="ml-3 text-danger">Kein Kommentar vorhanden!</span>
                  </b-col>
                  <b-col cols="auto" class="p-0">
                    <b-badge size="sm" variant="primary" class="ml-3 clickable" @click="addComment(license.id)">
                      Kommentar hinzufügen
                    </b-badge>
                  </b-col>
                </b-row>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </b-card-body>
</template>

<script>
import Comment from "@/components/lists/licensesList/comment/Comment";

export default {
  name: "LicenseDetails",
  props: ['pc', 'fg'],
  components: {
    Comment
  },
  methods: {
    addComment(licenseId) {
      this.$emit('add-comment', licenseId)
    },
    updateComment(data) {
      this.$emit('update-comment', data)
    },
    refresh() {
      this.$emit('refresh')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../assets/styles/Licenses';
</style>
