<template>
  <b-popover v-if="fg" :target="'comment-' + comment.id"
             :show.sync="popoverShow" placement="left">
    <template #title>Aktionen</template>
    <b-button-group size="sm">
      <b-button :id="'tt-verifiy' + comment.id" variant="primary" @click="verifyComment(comment.id)">
        <b-icon-check-circle/>
      </b-button>
      <b-button :id="'tt-unverifiy' + comment.id" variant="primary" @click="unverifyComment(comment.id)">
        <b-icon-x-circle/>
      </b-button>
      <b-button :id="'tt-add' + comment.id" variant="primary" @click="addNotice(comment.id)">
        <b-icon-chat-square-text/>
      </b-button>
      <b-button :id="'tt-remove' + comment.id" variant="primary" @click="removeNotice(comment.id)">
        <b-iconstack scale="0.75" shift-v="2" shift-h="-2">
          <b-icon-chat-square/>
          <b-icon-x shift-v="2"/>
        </b-iconstack>
      </b-button>
    </b-button-group>
    <div class="mt-2">
      <b-form-textarea size="sm" trim v-model="message"/>
    </div>
    <b-tooltip :target="'tt-verifiy' + comment.id">
      Kommentar verifizieren
    </b-tooltip>
    <b-tooltip :target="'tt-unverifiy' + comment.id">
      Verifizierung entfernen
    </b-tooltip>
    <b-tooltip :target="'tt-add' + comment.id">
      Kommentar kommentieren
    </b-tooltip>
    <b-tooltip :target="'tt-remove' + comment.id">
      Kommentiertes Kommentar zurückziehen
    </b-tooltip>
  </b-popover>
</template>

<script>
import {putRequest} from "@/modules/requests";

export default {
  name: "CommentPopover",
  props: ['fg', 'comment'],
  data() {
    return {
      popoverShow: false,
      message: ''
    }
  },
  methods: {
    hidePopover() {
      this.popoverShow = false
    },
    async verifyComment(commentId) {
      await putRequest('licenses/fg/comments/verify/' + commentId, null,  this, 'Kommentar wurde erfolgreich verifiziert.', 'Kommentar konnte nicht verifiziert werden.')
          .then(() => {
            this.$emit('refresh')
            this.hidePopover()
          })
    },
    async unverifyComment(commentId) {
      await putRequest('licenses/fg/comments/unverify/' + commentId, null, this, 'Die Verifizierung des Kommentars wurde erfolgreich entfernt.', 'Die Verifizierung des Kommentars konnte nicht entfernt werden.')
          .then(() => {
            this.$emit('refresh')
            this.hidePopover()
          })
    },
    async addNotice(commentId) {
      let data = {
        message: this.message
      }
      await putRequest('licenses/fg/comments/notice-add/' + commentId, data, this, 'Neue Notiz wurde erfolgreich erstellt.', 'Neue Notiz konnte nicht erstellt werden.')
          .then(() => {
            this.message = ''
            this.$emit('refresh')
            this.hidePopover()
          })
    },
    async removeNotice(commentId) {
      await putRequest('licenses/fg/comments/notice-remove/' + commentId, null, this, 'Die Notiz wurde erfolgreich entfernt.', 'Die Notiz konnte nicht entfernt werden.')
          .then(() => {
            this.$emit('refresh')
            this.hidePopover()
          })
    },
  }
}
</script>

<style lang="scss" scoped>
</style>
